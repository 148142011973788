import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import InputText from '../../../DevComponents/InputText'

import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../../Services/BackEndAPI'
import { FilialInterface } from '../../../ImportBackend/Interfaces/FilialInterfaces'
import { PesquisarLaudoInputInterface, rsLaudoErroCalculoInterface, rsLaudoPendenteAmostraInterface, rsLaudoPendenteInterface } from '../../../ImportBackend/Interfaces/LaudoInterfaces'
import PesquisarTabela from '../../../DevComponents/PesquisarTabela'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import { clsUtils } from 'zlib-utils'
import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import ModalComponent from '../../../DevComponents/ModalComponent'
import { getToken } from '../../..'
import axios from 'axios'
import { ENDPOINT } from '../../../ImportBackend/Config/emDesenvolvimento'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import ClsAcesso from '../../../Utils/ClsAcesso'
import { SISTEMA_PERMISSOES } from '../../../ImportBackend/types/AcessosDataTypes'
import ClsFormatacao from '../../../Utils/ClsFormatacao'

export default function EmitirLaudo () {

  const navigate = useNavigate()
  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const clsApi = new BackEndAPI()

  const clsFormatos: ClsFormatacao = new ClsFormatacao()

  const btFechar = () => {
    navigate( '/' )
  }

  const cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'OS',
      campo: 'idOrdemServico'
    },
    {
      cabecalho: 'Data Cadastro',
      campo: 'dataCadastroOS',
      alinhamento: 'left',
      format: ( data: string ) => clsFormatos.dataMillsToUser( parseInt( data ) )
    },
    {
      cabecalho: 'Lote',
      campo: 'idLote'
    },
    {
      cabecalho: 'Remetente',
      campo: 'remetente'
    },
    {
      cabecalho: 'Proprietario',
      campo: 'proprietario'
    },
    {
      cabecalho: 'Propriedade',
      campo: 'propriedade'
    },
    {
      cabecalho: 'Cidade',
      campo: 'cidade'
    },
    {
      cabecalho: 'UF',
      campo: 'uf'
    },
    {
      cabecalho: 'Ultimo Laudo',
      campo: 'dataEmissao',
      alinhamento: 'left',
      format: ( data: string ) => data.length > 0 ? clsFormatos.dataMillsToUser( parseInt( data ) ) : ''
    },
    {
      cabecalho: 'Material',
      campo: 'Material',
      format: ( v ) => v.descricao
    },
    {
      cabecalho: 'QTD',
      campo: 'qtdAmostras',
      alinhamento: 'right'
    },
    {
      cabecalho: 'Laudo',
      campo: 'laudoEmitido',
      alinhamento: 'right',
      format: ( rs ) => rs ? 'Sim' : 'Não'
    }
  ]

  const [pesquisa, setPesquisa] = useState<PesquisarLaudoInputInterface>( {
    idFilial: 0,
    idOrdemServico: 0,
    idClienteRemetente: 0,
    laudoEmitido: false
  } )

  const [rsOrdensServicosEmissaoLaudo, setRsOrdensServicosEmissaoLaudo] = useState<Array<rsLaudoPendenteInterface>>( [] )

  const pesquisarOrdensServicosStatusCadastradas = () => {

    const query = `
      pesquisarLaudo( pesquisa: ${clsUtils.ConverterEmGql( pesquisa )} ) {
        idLote
        idOrdemServico
        remetente
        proprietario
        propriedade
        cidade
        uf
        observacao
        idMaterial
        qtdAmostras
        status
        laudoEmitido
        dataEmissao
        dataCadastroOS
        Material {
          descricao
        }
        amostrasPendentes {
          nome
          numeroAmostra
          descricao
          dataProducao
        }
      }
    `

    clsApi.query<Array<rsLaudoPendenteInterface>>( query, 'pesquisarLaudo', 'Pesquisando Ordens de Serviços Em Aberto...', contexto ).then( rsOrdensServicosPendentesProducao => {
      setRsOrdensServicosEmissaoLaudo( rsOrdensServicosPendentesProducao )
    } )

  }

  const btPesquisarLaudos = () => {

    pesquisarOrdensServicosStatusCadastradas()

  }

  /* Exibição das Pendências */
  const cabecalhoPendencias: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'Amostra',
      campo: 'numeroAmostra'
    },
    {
      cabecalho: 'Determinação',
      campo: 'nome'
    },
    {
      cabecalho: 'Tipo Mapa',
      campo: 'descricao'
    },
    {
      cabecalho: 'Data Produção',
      campo: 'dataProducao',
      format: ( data: string ) => data ? clsFormatos.dataMillsToUser( parseInt( data ) ) : ''
    }
  ]

  const [rsExibirPendencias, setRsExibirPendencias] = useState<boolean>( false )
  const [rsPendencias, setRsPendencias] = useState<Array<rsLaudoPendenteAmostraInterface>>( [] )

  const btExibirPendencias = ( rs: rsLaudoPendenteInterface ) => {
    setRsPendencias( rs.amostrasPendentes )
    setRsExibirPendencias( true )
  }

  /* Erros na emissão do Laudo */
  const cabecalhoErroEmissaoLaudo: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'Amostra',
      campo: 'numeroAmostra'
    },
    {
      cabecalho: 'Determinação',
      campo: 'determinacao'
    },
    {
      cabecalho: 'Erro',
      campo: 'mensagem'
    }
  ]

  const [rsExibirErroEmissaoLaudo, setRsExibirErroEmissaoLaudo] = useState<boolean>( false )
  const [rsErroEmissaoLaudo, setRsErroEmissaoLaudo] = useState<Array<rsLaudoErroCalculoInterface>>( [] )

  const btEmitirLaudo = ( rs: rsLaudoPendenteInterface, indice: number ) => {

    const query = `
      emitirLaudo( idLote: ${rs.idLote} ) {
        numeroAmostra
        determinacao
        mensagem
      }
    `

    clsApi.query<Array<rsLaudoErroCalculoInterface>>( query, 'emitirLaudo', 'Emitindo Laudo...', contexto ).then( rsErrosEmissaoLaudo => {
      setRsErroEmissaoLaudo( rsErrosEmissaoLaudo )
      setRsExibirErroEmissaoLaudo( rsErrosEmissaoLaudo.length > 0 )

      if ( rsErrosEmissaoLaudo.length === 0 ) {
        const tmpOS = [...rsOrdensServicosEmissaoLaudo]
        tmpOS[indice].laudoEmitido = true
        setRsOrdensServicosEmissaoLaudo( tmpOS )
      }
    } )

  }

  const btReEmitirLaudo = ( rs: rsLaudoPendenteInterface, indice: number ) => {

    const query = `
      reEmitirLaudo( idLote: ${rs.idLote} ) {
        numeroAmostra
        determinacao
        mensagem
      }
    `

    clsApi.query<Array<rsLaudoErroCalculoInterface>>( query, 'reEmitirLaudo', 'Emitindo Laudo...', contexto ).then( rsErrosEmissaoLaudo => {
      setRsErroEmissaoLaudo( rsErrosEmissaoLaudo )
      setRsExibirErroEmissaoLaudo( rsErrosEmissaoLaudo.length > 0 )

      if ( rsErrosEmissaoLaudo.length === 0 ) {
        const tmpOS = [...rsOrdensServicosEmissaoLaudo]
        tmpOS[indice].laudoEmitido = true
        setRsOrdensServicosEmissaoLaudo( tmpOS )
      }
    } )

  }

  const permitirReemissao: boolean = ( new ClsAcesso() ).chkAcesso( contexto.loginState.permissoes, SISTEMA_PERMISSOES.LAUDO.MODULO, SISTEMA_PERMISSOES.LAUDO.PERMISSOES.REEMISSAO )

  /* Download */

  const nomeArquivo = ( rs: rsLaudoPendenteInterface, extensao: string ): string => {
    let retorno: string = rs.remetente
    retorno = retorno.concat( 'OS ', rs.idOrdemServico.toFixed( 0 ).padStart( 8, "0" ).slice( -8 ) )
    retorno = retorno.concat( ' Lote ', ( rs.idLote as number ).toFixed( 0 ).padStart( 8, "0" ).slice( -8 ) )

    return retorno.concat( '.'.concat( extensao ) )
  }

  const btDownload = ( rs: rsLaudoPendenteInterface ) => {

    contexto.setMensagemState( {
      mensagem: 'Downloading...',
      exibir: true,
      exibirBotao: false,
      titulo: 'Aguarde!',
      tipo: 'Loading',
      cb: null
    } )

    // console.log( { idLote: rs.idLote, token: getToken() } )

    axios.post( ENDPOINT.concat( 'downloadUltimoLaudoPorLote' ), {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      // responseType: 'blob'
      data: { idLote: rs.idLote, token: getToken() }
      // onUploadProgress: this.calculaprogressoUpload
    } ).then( ( response ) => {

      contexto.setMensagemState( { ...contexto.mensagemState, exibir: false } )

      const linkSource = `data:application/pdf;base64,${response.data}`

      const downloadLink = document.createElement( 'a' )
      document.body.appendChild( downloadLink )

      downloadLink.href = linkSource
      downloadLink.target = '_self'
      downloadLink.download = nomeArquivo( rs, 'pdf' )
      downloadLink.click()
      document.body.removeChild( downloadLink )

    } ).catch( e => {
      console.log( 'Erro: ', e )
      contexto.setMensagemState( {
        cb: null,
        exibir: true,
        exibirBotao: true,
        mensagem: 'Erro ao fazer download do laudo...',
        titulo: 'Erro!',
        tipo: MensagemTipo.Error
      } )
    } )

  }

  const btExportarTXT = ( rs: rsLaudoPendenteInterface ) => {

    contexto.setMensagemState( {
      mensagem: 'Downloading TXT...',
      exibir: true,
      exibirBotao: false,
      titulo: 'Aguarde!',
      tipo: 'Loading',
      cb: null
    } )

    // console.log( { idLote: rs.idLote, token: getToken() } )

    axios.post( ENDPOINT.concat( 'downloadLaudoTxtPorLote' ), {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      // responseType: 'blob'
      data: { idLote: rs.idLote, token: getToken() }
      // onUploadProgress: this.calculaprogressoUpload
    } ).then( ( response ) => {

      contexto.setMensagemState( { ...contexto.mensagemState, exibir: false } )

      // const linkSource = `data:text/csv;charset=utf-8,${response.data}`
      const linkSource = `data:text/plain;charset=utf-8,%EF%BB%BF${response.data}`
      // const linkSource = `${response.data}`

      const downloadLink = document.createElement( 'a' )
      document.body.appendChild( downloadLink )

      downloadLink.href = linkSource
      downloadLink.target = '_self'
      downloadLink.download = nomeArquivo( rs, 'csv' )
      downloadLink.click()
      document.body.removeChild( downloadLink )

    } ).catch( e => {
      console.log( 'Erro: ', e )
      contexto.setMensagemState( {
        cb: null,
        exibir: true,
        exibirBotao: true,
        mensagem: 'Erro ao fazer download do laudo...',
        titulo: 'Erro!',
        tipo: MensagemTipo.Error
      } )
    } )

  }

  return (
    <>

      {/* Erros na Emissão do Laudo */}

      <ModalComponent
        exibir={rsExibirErroEmissaoLaudo}
        setExibir={setRsExibirErroEmissaoLaudo}
      >

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Erros
                <Typography variant="body2" gutterBottom>
                  Erros de Cálculos na Emissão do Laudo
                </Typography>
              </Typography>

              <IconButton onClick={() => setRsExibirErroEmissaoLaudo( false )}>
                <CloseIcon />
              </IconButton>
            </Grid>

          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <DataTable
                cabecalho={cabecalhoErroEmissaoLaudo}
                dados={rsErroEmissaoLaudo}
              />
            </Grid>
          </Grid>
        </Paper>

      </ModalComponent>

      {/* Pendências */}

      <ModalComponent
        exibir={rsExibirPendencias}
        setExibir={setRsExibirPendencias}
      >

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Pendências
                <Typography variant="body2" gutterBottom>
                  Leituras Pendentes para Emissão de Laudo
                </Typography>
              </Typography>

              <IconButton onClick={() => setRsExibirPendencias( false )}>
                <CloseIcon />
              </IconButton>
            </Grid>

          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <DataTable
                cabecalho={cabecalhoPendencias}
                dados={rsPendencias}
              />
            </Grid>
          </Grid>
        </Paper>

      </ModalComponent>

      {/* Corpo Principal */}

      <Container maxWidth="xl" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Emissão de Laudos por lotes de amostras
                <Typography variant="body2" gutterBottom>
                  Pesquise por OS, pendentes por filial ou todas os lotes pendentes
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

          </Grid>

          <Grid container>

            <Grid item xs={12}>

              <PesquisarTabela<any>
                setState={setPesquisa}
                field='idCliente'
                fieldSet='idClienteRemetente'
                label='Remetente'
                dados={pesquisa}
                campoQueryPesquisaID='idCliente'
                campoQueryPesquisa='pesquisa'
                camposRetornoQueryPesquisa='{idCliente, nomeRazaoSocial}'
                campoLabelQueryPesquisa='nomeRazaoSocial'
                nomeQueryPesquisa='getClientes'
                nomeQueryPesquisaID='getClientePorId'
                mensagemPesquisa='Procurando Clientes...'
                valorAtribuirLimpar={0}
              />

            </Grid>

            <Grid item xs={12} md={3}>
              <InputText
                dados={pesquisa}
                field='idOrdemServico'
                label='Ordem de Serviços'
                setState={setPesquisa}
                maxLength={8}
                tipo='number'
                textAlign='right'
                onChange={( rs: number ) => setPesquisa( { idClienteRemetente: 0, idFilial: 0, idOrdemServico: rs, laudoEmitido: pesquisa.laudoEmitido } )}
              />
            </Grid>

            <Grid item xs={12} md={5} sx={{ pl: { md: 1 } }}>

              <PesquisarTabela<FilialInterface>
                setState={setPesquisa}
                field='idFilial'
                fieldSet='idFilial'
                label='Filial'
                dados={pesquisa}
                campoQueryPesquisaID='idFilial'
                campoQueryPesquisa='pesquisa'
                camposRetornoQueryPesquisa='{idFilial, descricao}'
                campoLabelQueryPesquisa='descricao'
                nomeQueryPesquisa='getFiliais'
                nomeQueryPesquisaID='getFilialPorId'
                mensagemPesquisa='Procurando Filiais...'
                pesquisarTudoAoIniciar
                valorAtribuirLimpar={0}
              />

            </Grid>

            <Grid item xs={12} md={2} sx={{ mt: 4.5, pl: { md: 3 } }}>

              <InputText
                dados={pesquisa}
                field='laudoEmitido'
                label='Laudo Emitido'
                setState={setPesquisa}
                tipo='checkbox'
              />

            </Grid>

            <Grid item textAlign='right' xs={12} md={2} sx={{ mt: 4.5, pl: { md: 1 } }}>
              <Button variant='contained' onClick={() => btPesquisarLaudos()} >Pesquisar</Button>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable
                cabecalho={cabecalho}
                dados={rsOrdensServicosEmissaoLaudo}
                acoes={[{
                  icone: 'send',
                  onAcionador: ( rs, indice ) => btEmitirLaudo( rs, indice ),
                  toolTip: 'Emitir',
                  onDisabled: ( rs: rsLaudoPendenteInterface ) => rs.amostrasPendentes.length > 0 || rs.laudoEmitido
                },
                {
                  icone: 'replay',
                  onAcionador: ( rs, indice ) => btReEmitirLaudo( rs, indice ),
                  toolTip: 'Re-Emitir',
                  onDisabled: ( rs: rsLaudoPendenteInterface ) => !rs.laudoEmitido || !permitirReemissao
                },
                {
                  icone: 'info',
                  onAcionador: ( rs: rsLaudoPendenteInterface ) => btExibirPendencias( rs ),
                  toolTip: 'Pendências',
                  onDisabled: ( rs: rsLaudoPendenteInterface ) => rs.amostrasPendentes.length === 0
                },
                {
                  icone: 'print',
                  onAcionador: ( rs ) => btDownload( rs ),
                  toolTip: 'Download',
                  onDisabled: ( rs: rsLaudoPendenteInterface ) => !rs.laudoEmitido
                },
                {
                  icone: 'save_alt',
                  onAcionador: ( rs ) => btExportarTXT( rs ),
                  toolTip: 'Exportar TXT',
                  onDisabled: ( rs: rsLaudoPendenteInterface ) => !rs.laudoEmitido
                }
                ]}
              />
            </Grid>

          </Grid>

        </Paper>
        <ExibirJSONDev oque={['Pesquisa', pesquisa, 'rsOrdensServicosEmissaoLaudo', rsOrdensServicosEmissaoLaudo]} />
      </Container>
    </>
  )
}