import React, { useContext, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../../Services/BackEndAPI'
import { useNavigate } from 'react-router-dom'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import InputText from '../../../DevComponents/InputText'
import ClsFormatacao from '../../../Utils/ClsFormatacao'
import ClsValidacao from '../../../Utils/ClsValidacao'
import PesquisarTabela from '../../../DevComponents/PesquisarTabela'
import { TipoMapaProducaoInterface } from '../../../ImportBackend/Interfaces/TipoMapaProducaoInterfaces'
import { MapaProducaoInterface } from '../../../ImportBackend/Interfaces/MapaProducaoInterfaces'
import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import ExibirBandeja from './ExibirBandeja'
import Condicional from '../../../Layout/Condicional'
import ComboBox from '../../../DevComponents/ComboBox'
import { DeterminacaoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'
import Leitura from './Leitura'
import { StatusMapaTypes } from '../../../ImportBackend/types/ConstantesDataTypes'

enum StatusType {
  PESQUISA = 0,
  BANDEJA = 1,
  LEITURA = 2
}

export default function ExibirMapas () {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const clsFormatos: ClsFormatacao = new ClsFormatacao()

  const [status, setStatus] = useState<StatusType>( StatusType.PESQUISA )

  const [rsMapas, setRsMapas] = useState<Array<MapaProducaoInterface>>( [] )

  const [rsDeterminacoesPorTipoMapaProducao, setRsDeterminacoesPorTipoMapaProducao] = useState<Array<DeterminacaoInterface>>( [] )

  const abortController: AbortController = new AbortController()

  const navigate = useNavigate()

  const clsApi = new BackEndAPI()

  const [pesquisa, setPesquisa] = useState( {
    idTipoMapaProducao: 0,
    idMapaProducao: 0,
    idDeterminacao: 0,
    bandeja: 0,
    dataProducao: clsFormatos.dateParaDateSQL( new Date() )
  } )

  const [errosPesquisa, setErrosPesquisa] = useState<Record<string, string>>( {} )

  const btFechar = () => {
    navigate( '/' )
  }

  const validarPesquisa = () => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    if ( pesquisa.dataProducao.length > 0 ) {
      retorno = clsValidacao.eData( 'dataProducao', pesquisa, erros, retorno )
    }

    // retorno = clsValidacao.naoVazio( 'idTipoMapaProducao', pesquisa, erros, retorno )

    setErrosPesquisa( erros )

    return retorno
  }

  const btPesquisar = () => {
    if ( validarPesquisa() ) {
      pesquisar()
    }
  }

  const pesquisar = () => {
    const query: string = `
        getMapasPorDataProducaoTipoMapaProducao (
          dataProducao: "${pesquisa.dataProducao}", 
          idTipoMapaProducao: ${pesquisa.idTipoMapaProducao}, 
          idDeterminacao: ${pesquisa.idDeterminacao} 
        ) {
          idMapaProducao
          idTipoMapaProducao
          bandeja
          qtdAmostras
          status
          dataProducao
          inicioProducao
          terminoProducao
          TipoMapaProducao {
            idTipoMapaProducao
            descricao
          }
        }
      `

    clsApi.query<Array<MapaProducaoInterface>>( query, 'getMapasPorDataProducaoTipoMapaProducao', 'Pesquisando Mapas...', contexto, abortController ).then( ( rs ) => {
      // console.log( rs )
      setRsMapas( rs )
    } )
  }

  const pesquisarDeterminacoesPorTipoMapaProducao = ( idTipoMapaProducao: number ) => {

    if ( idTipoMapaProducao === 0 ) {

      setRsDeterminacoesPorTipoMapaProducao( [] )

    } else {

      const query: string = `
        getDeterminacoesPorTipoMapaProducao (idTipoMapaProducao: ${idTipoMapaProducao} ) {
          idDeterminacao
          nome
        }
      `

      clsApi.query<Array<DeterminacaoInterface>>( query, 'getDeterminacoesPorTipoMapaProducao', 'Pesquisando Determinacoes...', contexto, abortController ).then( ( rs ) => {
        setRsDeterminacoesPorTipoMapaProducao( rs )
      } )

    }
  }

  const cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'Data Produção',
      campo: 'dataProducao',
      alinhamento: 'left',
      format: ( data: string ) => clsFormatos.dataISOtoUser( data )
    },
    {
      cabecalho: 'Mapa',
      campo: 'idTipoMapaProducao',
      alinhamento: 'left',
      format: ( _idTipoMapaProducao, rs ) => rs.TipoMapaProducao.descricao
    },
    {
      cabecalho: 'Bandeja',
      campo: 'bandeja',
      alinhamento: 'left'
    },
    {
      cabecalho: 'Qtd. Amostras',
      campo: 'qtdAmostras',
      alinhamento: 'center'
    },
    {
      cabecalho: 'Status',
      campo: 'status',
      alinhamento: 'left',
      format: ( rs ) => StatusMapaTypes.find( v => rs === v.idStatusMapa )?.descricao
    },
    {
      cabecalho: 'Início Produção',
      campo: 'inicioProducao',
      alinhamento: 'left',
      format: ( d: string | null ) => d ? clsFormatos.dataISOtoUser( d ) : ''
    },
    {
      cabecalho: 'Término Produção',
      campo: 'terminoProducao',
      alinhamento: 'left',
      format: ( d: string | null ) => d ? clsFormatos.dataISOtoUser( d ) : ''
    }
  ]

  const btVisualizar = ( bandeja: number, idMapaProducao: number ) => {

    setStatus( StatusType.BANDEJA )
    setPesquisa( { ...pesquisa, idMapaProducao: idMapaProducao, bandeja: bandeja } )

  }

  const btDigitarLeitura = ( idMapaProducao: number ) => {
    //    console.log( 'btDigitarLeitura', 'idMapaProducao', idMapaProducao, 'idDeterminacao', pesquisa.idDeterminacao, 'idTipoMapaProducao', pesquisa.idTipoMapaProducao )
    if ( pesquisa.idDeterminacao > 0 ) {
      setPesquisa( { ...pesquisa, idMapaProducao: idMapaProducao } )
      setStatus( StatusType.LEITURA )
    }
  }

  const onChangeDataProducao = ( data: string ) => {
    setPesquisa( { ...pesquisa, dataProducao: data } )
    setRsMapas( [] )
  }

  const onChangeTipoMapaProducao = ( rsTipoMapaProducao: TipoMapaProducaoInterface ) => {
    const idTipoMapaProducao: number = rsTipoMapaProducao && rsTipoMapaProducao.idTipoMapaProducao ? rsTipoMapaProducao.idTipoMapaProducao : 0

    pesquisarDeterminacoesPorTipoMapaProducao( idTipoMapaProducao )

    setPesquisa( { ...pesquisa, idTipoMapaProducao: idTipoMapaProducao, idDeterminacao: 0 } )
    setRsMapas( [] )

  }

  const onCloseExibirBandeja = () => {
    setStatus( StatusType.PESQUISA )
  }

  const onChangeIdDeterminacao = ( rs: DeterminacaoInterface ) => {
    setPesquisa( { ...pesquisa, idDeterminacao: rs ? rs.idDeterminacao as number : 0 } )
    setRsMapas( [] )
  }

  return (
    <>

      <Condicional condicao={status === StatusType.LEITURA}>
        <Leitura
          idDeterminacao={pesquisa.idDeterminacao}
          idMapaProducao={pesquisa.idMapaProducao}
          onClose={onCloseExibirBandeja}
          bandeja={pesquisa.bandeja}
        />
      </Condicional>

      <Condicional condicao={status === StatusType.BANDEJA}>
        <ExibirBandeja
          bandeja={pesquisa.bandeja}
          idMapaProducao={pesquisa.idMapaProducao}
          onClose={onCloseExibirBandeja}
        />
      </Condicional>

      <Condicional condicao={status === StatusType.PESQUISA}>
        <Container maxWidth="lg" sx={{ mt: 5 }}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography component="h5" variant="h5" align="left">
                  Mapas de Produção
                  <Typography variant="body2" gutterBottom>
                    Consultar Mapas e Digitar Resultados
                  </Typography>
                </Typography>

                <IconButton onClick={() => btFechar()}>
                  <CloseIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12} md={3} >

                <InputText
                  dados={pesquisa}
                  field='dataProducao'
                  label='Data da Produção'
                  type='date'
                  erros={errosPesquisa}
                  setState={setPesquisa}
                  onChange={( v: string ) => onChangeDataProducao( v )}
                  iconeEnd='clear'
                  onClickIconeEnd={() => setPesquisa( { ...pesquisa, dataProducao: '' } )}
                />

              </Grid>

              <Grid item xs={12} md={4} sx={{ pl: { md: 1 } }}>

                <PesquisarTabela<TipoMapaProducaoInterface>
                  field='idTipoMapaProducao'
                  fieldSet='idTipoMapaProducao'
                  label='Mapa de Produção'
                  dados={pesquisa}
                  campoQueryPesquisaID='idTipoMapaProducao'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idTipoMapaProducao descricao qtdAmostrasPorBandeja capacidadeProducaoDiaria}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getTiposMapaProducao'
                  nomeQueryPesquisaID='getTipoMapaProducaoPorId'
                  mensagemPesquisa='Procurando Tipos de Mapas de Produção...'
                  pesquisarTudoAoIniciar
                  erros={errosPesquisa}
                  onChange={( rs: TipoMapaProducaoInterface ) => onChangeTipoMapaProducao( rs )}
                />

              </Grid>

              <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>

                <ComboBox
                  campoDescricao='nome'
                  campoID='idDeterminacao'
                  opcoes={rsDeterminacoesPorTipoMapaProducao}
                  dados={pesquisa}
                  erros={errosPesquisa}
                  field='idDeterminacao'
                  label='Determinacao'
                  onChange={( rs: DeterminacaoInterface ) => onChangeIdDeterminacao( rs )}
                />

              </Grid>

              <Grid textAlign='right' item xs={12} md={2} sx={{ pl: { md: 1 }, mt: 4 }}>
                <Button variant='contained' onClick={() => btPesquisar()} >Pesquisar</Button>
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsMapas} cabecalho={cabecalho}
                  acoes={[
                    { icone: 'visibility', toolTip: 'Visualizar', onAcionador: ( v: MapaProducaoInterface ) => btVisualizar( v.bandeja, v.idMapaProducao as number ) }
                  ].concat( pesquisa.idDeterminacao ? [{ icone: 'create', toolTip: 'Leituras', onAcionador: ( v: MapaProducaoInterface ) => btDigitarLeitura( v.idMapaProducao as number ) }] : [] )}
                />
              </Grid>

            </Grid>
          </Paper>
        </Container>
      </Condicional>
    </>
  )

}