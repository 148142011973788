export interface TipoMaterialInterface {
  idTipoMaterial: string
  descricao: string
}

export enum TipoMaterialType {
  SOLO = 'S',
  OUTROS = 'O'
}

export const TipoMaterialTypes: Array<TipoMaterialInterface> = [
  { idTipoMaterial: 'S', descricao: 'Solo' },
  { idTipoMaterial: 'O', descricao: 'Outros' }
]

// Tipo de Cliente

export interface TipoClienteInterface {
  idTipoCliente: string
  descricao: string
}

export enum TipoClienteType {
  PF = 'PF',
  PJ = 'PJ'
}

export const TipoClienteTypes: Array<TipoClienteInterface> = [
  { idTipoCliente: TipoClienteType.PF, descricao: 'Pessoa Física' },
  { idTipoCliente: TipoClienteType.PJ, descricao: 'Pessoa Jurídica' }
]

// Tipo de Pagamento

export interface TipoPagamentoTypesInterface {
  idTipoPagamento: string
  descricao: string
}

/**
 * FATURADOENTRADA - Calcula o Prazo pela data da Entrada da Amostra
 * FATURADORESULTADO - Calcula o Prazo pela data de emissão do Resultado / Laudo
 * ANTECIPADO - Liberação de Laudo somente após o Pagamento
 * FECHAMENTOMENSAL - Fechamento Mensal e faturado conforme campo prazo
 */
export enum TipoPagamentoType {
  /** Calcula o Prazo pela data da Entrada da Amostra */
  FATURADOENTRADA = 'FE',
  /** Calcula o Prazo pela data de emissão do Resultado / Laudo */
  FATURADORESULTADO = 'DR',
  /** Liberação de Laudo somente após o Pagamento */
  ANTECIPADO = 'AN',
  /** Fechamento Mensal e faturado conforme campo prazo */
  FECHAMENTOMENSAL = 'FM'
}

export const TipoPagamentoTypes: Array<TipoPagamentoTypesInterface> = [
  { idTipoPagamento: TipoPagamentoType.ANTECIPADO, descricao: 'Antecipado' },
  { idTipoPagamento: TipoPagamentoType.FATURADOENTRADA, descricao: 'Prazo pelo Cadastro da Amostra' },
  { idTipoPagamento: TipoPagamentoType.FATURADORESULTADO, descricao: 'Prazo pela Emissão do Resultado' },
  { idTipoPagamento: TipoPagamentoType.FECHAMENTOMENSAL, descricao: 'Fechamento Mensal Conforme Prazo' }
]

// Canal de Notificacao

export interface CanalNotificacaoTypesInterface {
  idCanal: string
  descricao: string
}

export enum CanalNotificacaoType {
  WHATSAPP = 'WA',
  EMAIL = 'EM',
  TELEFONE = 'FO'
}

export const CanalNotificacaoTypes: Array<CanalNotificacaoTypesInterface> = [
  { idCanal: CanalNotificacaoType.WHATSAPP, descricao: 'Whats APP' },
  { idCanal: CanalNotificacaoType.EMAIL, descricao: 'e-mail' },
  { idCanal: CanalNotificacaoType.TELEFONE, descricao: 'Fone' }
]

// Tipo de Calculo / Entrada do Cadatro de Determinacoes

export interface TipoInformacaoDeterminacaoTypesInterface {
  idTipoInformacaoDeterminacao: string
  descricao: string
}

export enum TipoInformacaoDeterminacaoType {
  /* Entrada e Informação pelo Usuário */
  ENTRADA = 'ENTRADA',
  /* Cálculo a ser Executado */
  CALCULO = 'CALCULO',
  /* Parâmetro de Laboratório */
  SELECIONAR_PARAMETRO = 'SELECIONAR_PARAMETRO',
  /* Valor Fixo / Constante */
  CONSTANTE = 'CONSTANTE'
}

export const TipoInformacaoDeterminacaoTypes: Array<TipoInformacaoDeterminacaoTypesInterface> = [
  { idTipoInformacaoDeterminacao: TipoInformacaoDeterminacaoType.ENTRADA, descricao: 'Entrada' },
  { idTipoInformacaoDeterminacao: TipoInformacaoDeterminacaoType.CALCULO, descricao: 'Cálculo' },
  { idTipoInformacaoDeterminacao: TipoInformacaoDeterminacaoType.SELECIONAR_PARAMETRO, descricao: 'Selecionar Parâmetro' },
  { idTipoInformacaoDeterminacao: TipoInformacaoDeterminacaoType.CONSTANTE, descricao: 'Valor Fixo / Constante' }

]


// Tipo Parametro de Laboratorio

export enum MascaraTipoType {
  NI = 'NI',
  ND = 'ND',
  ST = 'ST'
}

export interface MascaraTipoInterface {
  idMascaraTipo: string
  descricao: string
}

export const MascaraTipoTypes: Array<MascaraTipoInterface> = [
  { idMascaraTipo: MascaraTipoType.NI, descricao: 'Número Inteiro' },
  { idMascaraTipo: MascaraTipoType.ND, descricao: 'Número Decimal' },
  { idMascaraTipo: MascaraTipoType.ST, descricao: 'Letras' }
]

// Fluxo de OS / Status de OS

export enum StatusOSType {
  CADASTRADA = 'CD',
  CANCELADA = 'CN',
  PRODUCAO_PARCIAL = 'PP',
  PRODUCAO = 'PR',
  REVISAO = 'RV',
  CONCLUIDA = 'CL'
}

export interface StatusOSInterface {
  idStatusOS: string
  descricao: string
}

export const StatusOSTypes: Array<StatusOSInterface> = [
  { idStatusOS: StatusOSType.CADASTRADA, descricao: 'Cadastrada' },
  { idStatusOS: StatusOSType.CANCELADA, descricao: 'Cancelada' },
  { idStatusOS: StatusOSType.PRODUCAO_PARCIAL, descricao: 'Em Produção (Parcial)' },
  { idStatusOS: StatusOSType.PRODUCAO, descricao: 'Em Produção' },
  { idStatusOS: StatusOSType.REVISAO, descricao: 'Em Revisão' },
  { idStatusOS: StatusOSType.CONCLUIDA, descricao: 'Concluída' }
]

// Fluxo de Amostras / Status de Amostras

export enum StatusAmostraType {
  CADASTRADA = 'CD',
  CANCELADA = 'CN',
  PRODUCAO_PARCIAL = 'PP',
  PRODUCAO = 'PR',
  REVISAO = 'RV',
  CONCLUIDA = 'CL'
}

export interface StatusAmostraInterface {
  idStatusAmostra: string
  descricao: string
}

export const StatusAmostraTypes: Array<StatusAmostraInterface> = [
  { idStatusAmostra: StatusAmostraType.CADASTRADA, descricao: 'Cadastrada' },
  { idStatusAmostra: StatusAmostraType.CANCELADA, descricao: 'Cancelada' },
  { idStatusAmostra: StatusAmostraType.PRODUCAO_PARCIAL, descricao: 'Em Produção Parcial' },
  { idStatusAmostra: StatusAmostraType.PRODUCAO, descricao: 'Em Produção' },
  { idStatusAmostra: StatusAmostraType.REVISAO, descricao: 'Em Revisão' },
  { idStatusAmostra: StatusAmostraType.CONCLUIDA, descricao: 'Concluída' }
]

// Unidade Determinação - Tipo da Entrada

export enum UnidadeDeterminacaoTipoInformacaoType {
  NUMERO = 'N',
  TEXTO = 'T'
}

export interface UnidadeDeterminacaoTipoInformacaoInterface {
  idUnidadeDeterminacaoTipoInformacao: string
  descricao: string
}

export const UnidadeDeterminacaoTipoInformacaoTypes: Array<UnidadeDeterminacaoTipoInformacaoInterface> = [
  { idUnidadeDeterminacaoTipoInformacao: UnidadeDeterminacaoTipoInformacaoType.NUMERO, descricao: 'Numérica' },
  { idUnidadeDeterminacaoTipoInformacao: UnidadeDeterminacaoTipoInformacaoType.TEXTO, descricao: 'Texto' }
]

// Unidade Determinação - Pós Cálculo

export enum UnidadeDeterminacaoPosCalculoType {
  ARREDONDAR = 'A',
  TRUNCAR = 'T'
}

export interface UnidadeDeterminacaoPosCalculoInterface {
  idUnidadeDeterminacaoPosCalculo: string
  descricao: string
}

export const UnidadeDeterminacaoPosCalculoTypes: Array<UnidadeDeterminacaoPosCalculoInterface> = [
  { idUnidadeDeterminacaoPosCalculo: UnidadeDeterminacaoPosCalculoType.ARREDONDAR, descricao: 'Arredondar' },
  { idUnidadeDeterminacaoPosCalculo: UnidadeDeterminacaoPosCalculoType.TRUNCAR, descricao: 'Truncar' }
]

// Fluxo de Mapas / Status de Mapas

export enum StatusMapaType {
  CADASTRADO = 'CD',
  PRODUCAO = 'PR',
  REVISAO = 'RV',
  CONCLUIDO = 'CL'
}

export interface StatusMapaInterface {
  idStatusMapa: string
  descricao: string
}

export const StatusMapaTypes: Array<StatusMapaInterface> = [
  { idStatusMapa: StatusMapaType.CADASTRADO, descricao: 'Cadastrado' },
  { idStatusMapa: StatusMapaType.PRODUCAO, descricao: 'Em Produção' },
  { idStatusMapa: StatusMapaType.REVISAO, descricao: 'Em Revisão' },
  { idStatusMapa: StatusMapaType.CONCLUIDO, descricao: 'Concluído' }
]

export const VALIDACAO_MASCARA: RegExp = /^((\.[9]+)|([9]+(\.[9]+)?))$/

export const VALIDACAO_NOME_VARAIVEL:RegExp = /^[A-Z_][0-9A-Z_]*$/

export const PREFIXO_VARIAVEL_PARAMETRO_LABORATORIO: string = 'PARAMETRO_LAB_'

export const ID_AMOSTR_PADRAO: number = 999999999